import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { API } from 'src/constants/api';
import { axiosPost } from 'src/utils/axios';

const initialState: any = {
    contractReview: {
        first_name: '',
        last_name: '',
        businessAddress: '',
        termsAgreement: false,
        fileName: '',
    },
    loading: false,
    hasErrors: false,
    hasErrorsList: {},
};

export const ContractReviewDetail = createAsyncThunk('/shop/contract', async (payload: any, { rejectWithValue }) => {
    try {
        const response: any = await axiosPost(API.CONTRACT.CREATE, payload);
        if (response.data.status === 200 || response.data.status === 201) {
            return response.data;
        }
        return rejectWithValue({
            data: response,
        } as any);
    } catch (err: any) {
        if (!err.response) {
            throw err;
        }
        return rejectWithValue({
            message: err.response.data,
        } as any);
    }
});

export const contractReviewSlice: any = createSlice({
    name: 'application',
    initialState: initialState,
    reducers: {
        updateChange: (state, { payload }) => {
            state.contractReview = payload;
            return state;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(ContractReviewDetail.pending, (state) => {
                state.loading = true;
                state.hasErrors = false;
            })
            .addCase(ContractReviewDetail.fulfilled, (state, { payload }) => {
                state.contractReview.fileName = payload.data.first_name;
                state.loading = false;
                state.hasErrors = payload.status;
            })
            .addCase(ContractReviewDetail.rejected, (state, { payload }: { payload: any }) => {
                state.loading = false;
                state.hasErrors = true;
                state.hasErrorsList = payload
                    ? {
                          error: payload,
                      }
                    : { error: 'Unknown error' };
            });
    },
});

export const { updateChange } = contractReviewSlice.actions;
