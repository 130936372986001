import React, { useEffect } from 'react';
import { Navigate, RouteProps } from 'react-router-dom';
import { selectUserInfo } from 'src/app/Auth/Login/Steps/Otp/otp.slice';
import NonGuard from 'src/components/NonGuard';
import { APP_FULL_NAME } from 'src/constants/common';
import { LocalStorageKeys } from 'src/constants/keys';
import { RoutesName } from 'src/constants/routes';
import { useAppSelector } from 'src/redux/hooks';

type NotGuardedRouteProps = {
    Component: React.ComponentType<any>;
    title?: string;
} & RouteProps;

const NotGuardedRoute: React.FC<NotGuardedRouteProps> = ({ Component, title }) => {
    useEffect(() => {
        document.title = `${title} - ${APP_FULL_NAME}`;
    }, [title]);

    const token = localStorage.getItem(LocalStorageKeys.authToken);

    const user = useAppSelector(selectUserInfo);

    return (
        <div>
            {user && token ? (
                <Navigate to={RoutesName.SIGNUP} />
            ) : (
                <NonGuard>
                    <Component />
                </NonGuard>
            )}
        </div>
    );
};

export default NotGuardedRoute;
