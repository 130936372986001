import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { API } from 'src/constants/api';
import { errorCode } from 'src/constants/errorCode';
import { RootState } from 'src/redux/store';
import { axiosPost } from 'src/utils/axios';

const initialState = {
    loading: false,
    hasErrors: false,
};

export const appLogoDetail = createAsyncThunk('/shop/app-logo', async (payload: any, { rejectWithValue }) => {
    try {
        const response: any = await axiosPost(API.USER.APP_LOGO, payload);
        if (response.data.status === errorCode.success || response.data.status === errorCode.updateSuccess) {
            return response.data;
        }
        return rejectWithValue({
            data: response,
        } as any);
    } catch (err: any) {
        if (!err.response) {
            throw err;
        }

        return rejectWithValue({
            message: err.response.data,
        } as any);
    }
});

export const appLogoSlice = createSlice({
    name: 'app-logo',
    initialState,
    reducers: {
        setAppLogoDeatil: (state, { payload }) => {
            // Modify payload to action
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(appLogoDetail.pending, (state) => {
                state.loading = true;
                state.hasErrors = false;
            })
            .addCase(appLogoDetail.fulfilled, (state, { payload }) => {
                state.loading = false;
                state.hasErrors = payload.status;
            })
            .addCase(appLogoDetail.rejected, (state, { payload }: { payload: any }) => {
                state.loading = false;
                state.hasErrors = true;
            });
    },
});

export const { setAppLogoDeatil } = appLogoSlice.actions;
export const selectAppLogoLoading = (state: RootState) => state.AppLogo.loading;
