import React from 'react';
import Footer from '../Footer/Footer';
import Header from '../Header/Header';

const Guard = ({ children }: any) => (
    <div className="min-h-screen">
        <Header />
        <div className="wrapper_content_step flex justify-center">{children}</div>
        <Footer />
    </div>
);

export default Guard;
