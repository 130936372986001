import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { API } from 'src/constants/api';
import { errorCode } from 'src/constants/errorCode';
import { RootState } from 'src/redux/store';
import { axiosPost } from 'src/utils/axios';

const initialState = {
    userdetails: {
        first_name: '',
        last_name: '',
        email: '',
    },
    loading: false,
    hasErrors: false,
    hasErrorsList: {},
};

export const accountDetail = createAsyncThunk('/shop/account', async (payload: any, { rejectWithValue }) => {
    try {
        const response: any = await axiosPost(API.USER.ACCOUNT, payload);
        if (response.data.status === errorCode.success || response.data.status === errorCode.updateSuccess) {
            return response.data;
        }
        return rejectWithValue({
            data: response,
        } as any);
    } catch (err: any) {
        if (!err.response) {
            throw err;
        }

        return rejectWithValue({
            message: err.response.data,
        } as any);
    }
});

export const accountDetailSlice = createSlice({
    name: 'account-detail',
    initialState,
    reducers: {
        setAccountDeatil: (state, { payload }) => {
            state.userdetails = {
                ...state.userdetails,
                first_name: payload.data.first_name,
                last_name: payload.data.last_name,
                email: payload.data.email,
            };
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(accountDetail.pending, (state) => {
                state.loading = true;
                state.hasErrors = false;
            })
            .addCase(accountDetail.fulfilled, (state, { payload }) => {
                state.userdetails = {
                    ...state.userdetails,
                    first_name: payload.data.first_name,
                    last_name: payload.data.last_name,
                    email: payload.data.email,
                };
                state.loading = false;
                state.hasErrors = payload.status;
            })
            .addCase(accountDetail.rejected, (state, { payload }: { payload: any }) => {
                state.loading = false;
                state.hasErrors = true;
                state.hasErrorsList = payload
                    ? {
                          error: payload,
                          first_name: payload.data.data.data.first_name,
                          last_name: payload.data.data.data.last_name,
                          email: payload.data.data.data.email,
                      }
                    : { error: 'Unknown error' };
            });
    },
});

export const { setAccountDeatil } = accountDetailSlice.actions;
export const selectAccountLoading = (state: RootState) => state.AccountDetail.loading;
