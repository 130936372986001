import { ComponentType, LazyExoticComponent } from 'react';

export const APP_FULL_NAME: string = process.env.REACT_APP_FULL_NAME as string;
export const REGION_CODE: any = process.env.REACT_APP_REGION_CODE;
export const TIMEZONE: any = process.env.REACT_APP_TIMEZONE;
export const baseURL: any = process.env.REACT_APP_BASE_URL;

export const DATE_FORMAT: string = 'YYYY-MM-DD';
export const DATE_TIME_FORMAT: string = 'YYYY-MM-DD hh:mm:ss';
export const TIME_FORMAT: string = 'hh:mm:ss';

export enum ERouteType {
    GUARDED = 'GUARDED',
    NOT_GUARDED = 'NOT_GUARDED',
    DEFAULT = 'DEFAULT',
}

export type RouteItem = {
    type: ERouteType;
    path: string;
    component: LazyExoticComponent<ComponentType>;
    title?: string;
};
