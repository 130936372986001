import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { API } from 'src/constants/api';
import { RootState } from 'src/redux/store';
import { axiosPost } from 'src/utils/axios';

const initialState = {
    loading: false,
    hasErrorsList: {},
};

export const userLogin = createAsyncThunk('shop/login', async (payload: any, { rejectWithValue }) => {
    try {
        const response = await axiosPost(API.USER.LOGIN_WITH_PHONE, payload);
        if (response.data.status === 200) {
            return response.data;
        }
        return rejectWithValue({
            data: response,
        } as any);
    } catch (err: any) {
        if (!err.response) {
            throw err;
        }

        return rejectWithValue({
            message: err.response.data,
        } as any);
    }
});

export const loginSlice = createSlice({
    name: 'login',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(userLogin.pending, (state) => {
                state.loading = true;
            })
            .addCase(userLogin.fulfilled, (state, { payload }) => {
                state.loading = false;
            })
            .addCase(userLogin.rejected, (state, { payload }) => {
                state.loading = false;
            });
    },
});

export const selectLoading = (state: RootState) => state.Login.loading;
