import React, { ComponentType, LazyExoticComponent } from 'react';
import { ERouteType, RouteItem } from 'src/constants/common';
import { RoutesName } from 'src/constants/routes';

const getLazyPage = (path: string): LazyExoticComponent<ComponentType> => React.lazy((): Promise<{ default: ComponentType }> => import(`src/app/${path}`));

export const Screens: RouteItem[] = [
    {
        type: ERouteType.NOT_GUARDED,
        path: RoutesName.HOME,
        component: getLazyPage('Auth/Login/Home'),
        title: 'Login',
    },
    {
        type: ERouteType.GUARDED,
        path: RoutesName.OTPVERIFY,
        component: getLazyPage('Auth/Login/Steps/OtpVerify'),
        title: 'OTP Verify',
    },
    {
        type: ERouteType.GUARDED,
        path: RoutesName.SIGNUP,
        component: getLazyPage('MultiSteps/Signup'),
        title: 'signup',
    },
];
