import { validType } from 'src/constants/fileTypeValidation';
import _ from 'lodash';

const logout = () => {
    localStorage.removeItem('persist:root');
    localStorage.removeItem('token');
    window.location.href = `${window.location.origin}/`;
};

const getSelectBoxOptions = (data: Array<any>, value: string = 'name', label: string = 'name', id: string = 'id', shortName: string = 'shortname') => {
    if (!Array.isArray(data)) {
        data = [data];
    }
    return data.map((res) => ({
        value: res[value],
        label: res[label],
        id: res[id],
        shortName: res[shortName],
    }));
};
const convertBase64ToFile = (appLogoImage: any) => {
    try {
        const imageExtension = appLogoImage.substring(appLogoImage.indexOf('/') + 1, appLogoImage.indexOf(';base64'));

        const currentTimestamp = Date.now();
        const randomNum = Math.floor(1000000000 + Math.random() * 9000000000);
        const filename = `${currentTimestamp}_${randomNum}.${imageExtension}`;
        const base64Data = appLogoImage.replace(/^data:[^;]+;base64,/, '');

        const uint8Array = Uint8Array.from(atob(base64Data), (c) => c.charCodeAt(0));

        const blob = new Blob([uint8Array], {
            type: 'application/octet-stream',
        });

        const convertedFile = new File([blob], filename, {
            type: 'application/octet-stream',
        });
        return {
            convertedFile: convertedFile,
            filename: filename,
        };
    } catch (error) {}
};

const checkFileTypeValidation = (uploadedFile: any, size: any) => {
    const validationResults = _.map(uploadedFile, (file: File) => {
        if (!validType.includes(file.type)) {
            return {
                result: false,
                message: 'Invalid file format. Please upload a valid image file.',
            };
        } else if (file.size > size * 1024) {
            return {
                result: false,
                message: `Image upload failed. Please ensure your image is within the maximum allowed size of 800kb`,
            };
        } else {
            return { result: true, message: '' };
        }
    });

    const validationResult = _.every(validationResults, (result: { result: boolean }) => result.result === true);

    return {
        result: validationResult,
        message: validationResult ? '' : validationResults[0].message,
    };
};

export { logout, getSelectBoxOptions, convertBase64ToFile, checkFileTypeValidation };
