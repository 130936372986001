import { combineReducers, configureStore } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';
import { FLUSH, PAUSE, PERSIST, PURGE, REGISTER, REHYDRATE, persistReducer, persistStore } from 'redux-persist';
import { loginSlice } from 'src/app/Auth/Login/Steps/Login/login.slice';
import { otpSlice } from 'src/app/Auth/Login/Steps/Otp/otp.slice';
import { accountDetailSlice } from 'src/app/MultiSteps/AccountDetail/AccountDetail.slice';
import { ChooesPlanSlice } from 'src/app/MultiSteps/ChooesPlan/ChooesPlan.slice';
import { businessBrandingSlice } from 'src/app/MultiSteps/BusinessBranding/BusinessBranding.slice';
import { companySlice } from 'src/app/MultiSteps/CompanyDetail/CompanyDetail.slice';
import { contractReviewSlice } from 'src/app/MultiSteps/ContactReview/ContractReview.slice';
import { appLogoSlice } from 'src/app/MultiSteps/AppLogo/AppLogo.slice';
import { PaymentMethod } from 'src/app/MultiSteps/PaymentMethod/PaymentMethod.slice';
import { CountrySlice } from './services/country/Country.slice';
import { StateSlice } from './services/state/State.slice';
import { CitySlice } from './services/city/City.slice';
const persistConfig = {
    key: 'root',
    storage,
    whitelist: ['User'],
};

const rootReducer = combineReducers({
    Login: loginSlice.reducer,
    User: otpSlice.reducer,
    AccountDetail: accountDetailSlice.reducer,
    Company: companySlice.reducer,
    ChoosePlan: ChooesPlanSlice.reducer,
    BusinessBranding: businessBrandingSlice.reducer,
    ContractReview: contractReviewSlice.reducer,
    AppLogo: appLogoSlice.reducer,
    PaymentMethod: PaymentMethod.reducer,
    Countries: CountrySlice.reducer,
    States: StateSlice.reducer,
    Cities: CitySlice.reducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: {
                // Ignore these action types
                ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
            },
        }),
});

export const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
