import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { API } from 'src/constants/api';
import { axiosGet, axiosPost } from 'src/utils/axios';
import { RootState } from 'src/redux/store';
import { ErrorType, IInitialState } from './otp.interface';

const initialState: IInitialState = {
    user: '',
    token: '',
    loading: false,
    error: null,
};

export const loginOtpVerify = createAsyncThunk('shop/login/otp', async (payload: any, { rejectWithValue }) => {
    try {
        const response = await axiosPost(API.USER.PHONE_VERIFY_OTP, payload);

        if (response.data.status === 200) {
            return response.data;
        }
        return rejectWithValue({
            data: response,
        } as any);
    } catch (err: any) {
        if (!err.response) {
            throw err;
        }

        return rejectWithValue({
            message: err.response.data,
        } as any);
    }
});

export const shopDetail = createAsyncThunk('shop/get', async (payload, { rejectWithValue }) => {
    try {
        const response = await axiosGet(API.USER.SHOP_DETAIL);
        if (response.data.status === 200) {
            return response.data.data;
        }
        return rejectWithValue(response as ErrorType);
    } catch (err: any) {
        if (!err.response) {
            throw err;
        }
        return rejectWithValue(err.response.data as ErrorType);
    }
});

export const otpSlice = createSlice({
    name: 'otp/verify',
    initialState,
    reducers: {
        setUser: (state, action) => {
            state.user = action.payload.data;
        },
        clearUser: () => initialState,
    },
    extraReducers: (builder) => {
        builder
            .addCase(loginOtpVerify.pending, (state) => {
                state.loading = true;
            })
            .addCase(loginOtpVerify.fulfilled, (state, { payload }) => {
                state.token = payload.data.token;
                state.loading = false;
            })
            .addCase(loginOtpVerify.rejected, (state, { payload }) => {
                state.loading = false;
            })
            .addCase(shopDetail.pending, (state) => {
                state.loading = true;
            })
            .addCase(shopDetail.fulfilled, (state, { payload }: any) => {
                state.loading = false;
                state.user = payload;
            })
            .addCase(shopDetail.rejected, (state) => {
                state.loading = false;
            });
    },
});

export const { setUser, clearUser } = otpSlice.actions;

export const selectUserInfo = (state: RootState) => state.User.user;
export const selectLoading = (state: RootState) => state.User.loading;
