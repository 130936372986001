/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { useTranslation } from 'react-i18next';

const Footer = () => {
    const { t } = useTranslation();
    return (
        <footer className="flex py-6 px-10 justify-between">
            <span className="text-secondaryTxtColor text-xs font-normal leading-4 -tracking-[0.12px]">&copy; {t('2023 getflair.ca')}</span>
            <div className="flex gap-6 leading-4">
                <a href="https://www.getflair.ca/terms-of-service" target="_blank" className="text-xs font-normal text-primary -tracking-[0.12px]">
                    {t('Terms & Conditions')}
                </a>
                <a href="https://www.getflair.ca/privacy-policy" target="_blank" className="text-xs font-normal text-primary -tracking-[0.12px]">
                    {t('Privacy Policy')}
                </a>
            </div>
        </footer>
    );
};

export default Footer;
