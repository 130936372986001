/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import NSidebar from './NSidebar';
import Logo from 'src/assets/svgs/Logo.svg';
import Footer from '../Footer/Footer';
const NonGuard = ({ children }: any) => (
    <>
        {/* left layout */}
        <div className="auth_wrapper flex">
            <div className="flex flex-col h-full basis-[620px] grow-0 shrink-0">
                <div className="h-full pt-[120px] px-[100px]">
                    <img src={Logo} alt="" />
                    {children}
                </div>
                <Footer />
            </div>
            <NSidebar />
        </div>
    </>
);

export default NonGuard;
