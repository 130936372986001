import axios from 'axios';
import { logout } from './global-functions';
import { IRequestClient } from './requestClient.interface';
import { LocalStorageKeys } from 'src/constants/keys';
import { toast } from 'react-toastify';
import { errorCode } from 'src/constants/errorCode';
import { baseURL } from 'src/constants/common';

export const axiosApi = axios.create({
    baseURL: baseURL,
    headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
    },
});

axiosApi.interceptors.request.use(
    (config: any) => {
        if (localStorage.getItem(LocalStorageKeys.authToken)) {
            config.headers.Authorization = `Bearer ${localStorage.getItem(LocalStorageKeys.authToken)}`;
        }
        return config;
    },
    (error: any) => Promise.reject(error),
);

axiosApi.interceptors.response.use(
    (response) => response,
    (error) => {
        if (error.response.status === errorCode.internalError || error.response.status === errorCode.notFound) {
            toast.error('We are sorry, but something went wrong. Please try again later.');
        }
        return Promise.reject(error);
    },
);

export const axiosPost = async (url: string, data: any, params?: object) => {
    const response: IRequestClient = {
        data: null,
    };

    try {
        if (params && Object.keys(params).length !== 0) {
            url = generateURL(url, params);
        }
        const result = await axiosApi.post(url, data);
        response.data = result.data;
    } catch (e: any) {
        if (e.response) {
            if (e.response?.status === 401) {
                response.data = e.response?.data;
                logout();
            } else {
                response.data = e.response?.data;
            }
        }
    }
    return response;
};

export const axiosPut = async (url: string, data: any, params?: object) => {
    let response: any = {};
    try {
        if (params && Object.keys(params).length !== 0) {
            url = generateURL(url, params);
        }

        const result = await axiosApi.put(url, data);
        response.status = true;
        response.data = result.data;
    } catch (e: any) {
        if (e.response) {
            if (e.response.status === 400) {
                response.status = false;
                response.message = e.response.data.message;
            } else if (e.response?.status === 401) {
                response.status = false;
                response.message = 'Unauthorized';
                logout();
            } else if (e.response.status === 403) {
                response.status = false;
                response.message = e.response.data.message;
            } else if (e.response.status === 500) {
                response.status = false;
                response.message = 'Internal server error';
            } else {
                response.status = false;
                response.message = 'something went wrong';
            }
        }
    }
    return response;
};

export const axiosGet = async (url: string, params: object = {}, data: any = {}) => {
    const response: IRequestClient = {
        data: null,
    };

    try {
        if (params && Object.keys(params).length !== 0) {
            url = generateURL(url, params);
        }
        const result = await axiosApi.get(url, { params: data });
        response.data = result.data;
    } catch (e: any) {
        if (e.response) {
            if (e.response?.status === 401) {
                response.data = e.response?.data;
                logout();
            } else {
                response.data = e.response?.data;
            }
        }
    }
    return response;
};

export const axiosDelete = async (url: string, data: any, params?: object) => {
    let response: any = {};
    if (params && Object.keys(params).length !== 0) {
        url = generateURL(url, params);
    }

    try {
        const result = await axiosApi.delete(url, data);

        response.status = true;
        response.data = result.data;
    } catch (e: any) {
        if (e.response.status === 400) {
            response.status = false;
            response.message = e.response.data.message;
        } else if (e.response?.status === 401) {
            response.status = false;
            response.message = 'Unauthorized';
            logout();
        } else if (e.response.status === 500) {
            response.status = false;
            response.message = 'Internal server error';
        } else {
            response.status = false;
            response.message = 'something went wrong';
        }
    }
    return response;
};

const generateURL = (url: string, pathParams: any) => {
    for (const param in pathParams) {
        url = url.replace(`{${param}}`, pathParams[param]);
    }
    return url;
};
