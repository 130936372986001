import React, { useEffect } from 'react';
import { RouteProps, Navigate, useLocation } from 'react-router-dom';
import Guard from 'src/components/Guard';
import NonGuard from 'src/components/NonGuard';
import { APP_FULL_NAME } from 'src/constants/common';
import { RoutesName } from 'src/constants/routes';
import { useAppDispatch, useAppSelector } from 'src/redux/hooks';
import { LocalStorageKeys } from 'src/constants/keys';
import { shopDetail } from 'src/app/Auth/Login/Steps/Otp/otp.slice';
import { selectUserInfo } from '../app/Auth/Login/Steps/Otp/otp.slice';

type GuardedRouteProps = {
    Component: React.ComponentType<any>;
    title?: string;
} & RouteProps;

const GuardedRoute: React.FC<GuardedRouteProps> = ({ Component, title, ...routeProps }): JSX.Element => {
    useEffect(() => {
        document.title = `${title} - ${APP_FULL_NAME}`;
    }, [title]);

    const token = localStorage.getItem(LocalStorageKeys.authToken);
    const user = useAppSelector(selectUserInfo);

    const dispatch = useAppDispatch();
    useEffect(() => {
        const fetchData = async () => {
            await dispatch(shopDetail());
        };
        fetchData();
    }, []);

    const location = useLocation();
    const arrayName = [RoutesName.OTPVERIFY];
    const currentPath = location.pathname;
    let checkCurrentpath = arrayName.includes(currentPath);

    return token && user ? (
        checkCurrentpath ? (
            <NonGuard>
                <Component />
            </NonGuard>
        ) : (
            <Guard>
                <Component />{' '}
            </Guard>
        )
    ) : (
        <Navigate to={RoutesName.HOME} />
    );
};

export default GuardedRoute;
