import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import { API } from 'src/constants/api';
import { errorCode } from 'src/constants/errorCode';

import { RootState } from 'src/redux/store';
import { IInitialState } from './CompanyDetail.interface';
import { axiosGet, axiosPost } from 'src/utils/axios';
interface ErrorType {
    message: string;
    status?: string;
    data?: any;
}

const initialState: IInitialState = {
    data: '',
    loading: false,
    error: null,
};

export const company = createAsyncThunk('/shop/company', async (payload: any, { rejectWithValue }) => {
    try {
        const response = await axiosPost(API.USER.COMPANY, payload);
        if (response.data.status === errorCode.success || response.data.status === errorCode.updateSuccess) {
            return response.data;
        }

        return rejectWithValue(response as ErrorType);
    } catch (err: any) {
        if (!err.response) {
            throw err;
        }
        return rejectWithValue(err.response.data as ErrorType);
    }
});

export const companyCountries = createAsyncThunk('shop/countries', async (payload, { rejectWithValue }) => {
    try {
        const response = await axiosGet(API.LOCATION.COUNTRIES);
        if (response.data.status === errorCode.success) {
            return response.data.data;
        }
        return rejectWithValue(response as ErrorType);
    } catch (err: any) {
        if (!err.response) {
            throw err;
        }
        return rejectWithValue(err.response.data as ErrorType);
    }
});

export const companyStates = createAsyncThunk('shop/states', async (payload: any, { rejectWithValue }) => {
    try {
        const response = await axiosGet(API.LOCATION.STATES, payload);
        if (response.data.status === errorCode.success || response.data.status === errorCode.updateSuccess) {
            return response.data.data;
        }
        return rejectWithValue(response as ErrorType);
    } catch (err: any) {
        if (!err.response) {
            throw err;
        }
        return rejectWithValue(err.response.data as ErrorType);
    }
});

export const companyCities = createAsyncThunk('shop/cities', async (payload, { rejectWithValue }) => {
    try {
        const response = await axiosGet(API.LOCATION.CITIES);
        if (response.data.status === errorCode.success) {
            return response.data.data;
        }
        return rejectWithValue(response as ErrorType);
    } catch (err: any) {
        if (!err.response) {
            throw err;
        }
        return rejectWithValue(err.response.data as ErrorType);
    }
});

export const companySlice = createSlice({
    name: 'shop/details',
    initialState,
    reducers: {
        setCompany: (state, { payload }) => {
            state.data = payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(company.pending, (state) => {
                state.loading = true;
            })
            .addCase(company.fulfilled, (state, { payload }: any) => {
                state.loading = false;
                state.data = payload.data;
            })
            .addCase(company.rejected, (state, { payload }: any) => {
                state.loading = false;
                state.data = payload.data;
            })
            .addCase(companyCountries.pending, (state) => {
                state.loading = true;
            })
            .addCase(companyCountries.fulfilled, (state, { payload }: any) => {
                state.loading = false;
            })
            .addCase(companyCountries.rejected, (state, { payload }: any) => {
                state.loading = false;
            })
            .addCase(companyStates.pending, (state) => {
                state.loading = true;
            })
            .addCase(companyStates.fulfilled, (state, { payload }: any) => {
                state.loading = false;
            })
            .addCase(companyStates.rejected, (state, { payload }: any) => {
                state.loading = false;
            })
            .addCase(companyCities.pending, (state) => {
                state.loading = true;
            })
            .addCase(companyCities.fulfilled, (state, { payload }: any) => {
                state.loading = false;
            })
            .addCase(companyCities.rejected, (state, { payload }: any) => {
                state.loading = false;
            });
    },
});

export const { setCompany } = companySlice.actions;

export const selectLoading = (state: RootState) => state.Company.loading;
export const selectError = (state: RootState) => state.Company.error;
