import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { API } from 'src/constants/api';
import { errorCode } from 'src/constants/errorCode';
import { RootState } from 'src/redux/store';
import { axiosGet, axiosPost } from 'src/utils/axios';

const initialState = {
    loading: false,
    hasErrors: false,
    themeList: [],
};

export const themeList = createAsyncThunk('/shop/heme/list', async (_, { rejectWithValue }) => {
    try {
        const response: any = await axiosGet(API.THEME.LIST);
        if (response.data.status === errorCode.success) {
            return response.data;
        }
        return rejectWithValue({
            data: response,
        } as any);
    } catch (err: any) {
        if (!err.response) {
            throw err;
        }

        return rejectWithValue({
            message: err.response.data,
        } as any);
    }
});

export const selctThemeDetail = createAsyncThunk('/shop/branding', async (payload: any, { rejectWithValue }) => {
    try {
        const response: any = await axiosPost(API.USER.BRANDING, payload);

        if (response.data.status === errorCode.success || response.data.status === errorCode.updateSuccess) {
            return response.data;
        }
        return rejectWithValue({
            data: response,
        } as any);
    } catch (err: any) {
        if (!err.response) {
            throw err;
        }

        return rejectWithValue({
            message: err.response.data,
        } as any);
    }
});

export const businessBrandingSlice = createSlice({
    name: 'account-detail',
    initialState,
    reducers: {
        setAccountDeatil: (state, { payload }) => {
            // Modify payload to action
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(themeList.pending, (state) => {
                state.loading = true;
                state.hasErrors = false;
            })
            .addCase(themeList.fulfilled, (state, { payload }) => {
                state.themeList = payload.data;
                state.loading = false;
                state.hasErrors = payload.status;
            })
            .addCase(themeList.rejected, (state, { payload }: { payload: any }) => {
                state.loading = false;
                state.hasErrors = true;
            })
            .addCase(selctThemeDetail.pending, (state: { loading: boolean; hasErrors: boolean }) => {
                state.loading = true;
                state.hasErrors = false;
            })
            .addCase(selctThemeDetail.fulfilled, (state: { loading: boolean; hasErrors: any }, { payload }: any) => {
                state.loading = false;
                state.hasErrors = payload.status;
            })
            .addCase(selctThemeDetail.rejected, (state: { loading: boolean; hasErrors: boolean }, { payload }: { payload: any }) => {
                state.loading = false;
                state.hasErrors = true;
            });
    },
});

export const { setAccountDeatil } = businessBrandingSlice.actions;
export const selectLoading = (state: RootState) => state.BusinessBranding.loading;
export const selectThemeList = (state: RootState) => state.BusinessBranding.themeList;
