import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { API } from 'src/constants/api';
import { axiosGet } from 'src/utils/axios';
import { ErrorType, IInitialState } from './State.interface';
// import { RootState } from 'src/redux/store';
import { errorCode } from 'src/constants/errorCode';

const initialState: IInitialState = {
    data: null,
    loading: false, // Add the loading property here
    error: null,
};

export const getState = createAsyncThunk('/shop/state', async (payload: any, { rejectWithValue }) => {
    try {
        const response = await axiosGet(API.LOCATION.STATES, {}, payload);
        if (response.data.status === errorCode.success || response.data.status === errorCode.updateSuccess) {
            return response.data;
        }

        return rejectWithValue(response as ErrorType);
    } catch (err: any) {
        if (!err.response) {
            throw err;
        }
        return rejectWithValue(err.response.data as ErrorType);
    }
});

export const StateSlice = createSlice({
    name: 'shop/state',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder

            .addCase(getState.pending, (state) => {
                state.loading = true;
            })
            .addCase(getState.fulfilled, (state, { payload }: any) => {
                state.loading = false;
                state.data = {
                    ...state.data,
                    state: payload.data,
                };
            })
            .addCase(getState.rejected, (state, action: any) => {
                state.loading = false;
            });
    },
});
export const { reducer: accountReducer } = StateSlice;
