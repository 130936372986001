export const API = {
    USER: {
        LOGIN_WITH_EMAIL: '/shop/login',
        LOGIN_WITH_PHONE: '/shop/phone/login',
        PHONE_VERIFY_OTP: '/shop/phone/verify-otp',
        LOGOUT: '/shop/phone/verify-otp',
        FORGOT_PASSWORD: '/shop/forgot-password',
        NEW_PASSWORD: '/shop/new-password',
        ACCOUNT: '/shop/account',
        COMPLETE_ACCOUNT: '/shop/complete-account',
        COMPANY: '/shop/company',
        ACCOUNT_LOCATION: '/shop/account/location',
        ADD_LOCATION_WORKING_HOURS: '/shop/account/location/{id}/working-hours',
        BRANDING: '/shop/branding',
        APP_LOGO: '/shop/app-logo',
        CONTRACT: '/shop/contract',
        BOOKING_PAYMENT: '/shop/booking-payment',
        SHOP_DETAIL: '/shop/me',
    },
    STRIPE: {
        LIST: '/shop/stripe/card/list',
        CREATE: '/shop/stripe/card/add',
        DELETE: '/shop/stripe/card/remove',
        ACCOUNT: '/shop/stripe/account',
        DEFAULT: '/shop/stripe/card/default',
    },
    THEME: {
        LIST: '/shop/theme/list',
    },
    PLAN: {
        UPDATE: '/shop/plan',
        GET: '/shop/plans',
    },
    CATEGORY: {
        LIST: '/shop/{shop_id}/category',
        CREATE: '/shop/{shop_id}/category',
        UPDATE: '/shop/{shop_id}/category/{id}',
        DELETE: '/shop/{shop_id}/category/{id}',
        GET: '/shop/{shop_id}/category/{id}',
    },
    LOCATION: {
        LIST: '/shop/{shop_id}/location',
        CREATE: '/shop/{shop_id}/location',
        UPDATE: '/shop/{shop_id}/location/{id}',
        DELETE: '/shop/{shop_id}/location/{id}',
        COUNTRIES: '/shop/countries',
        STATES: '/shop/states',
        CITIES: '/shop/cities',
    },
    PAYMENT: {
        UPDATE: '/shop/{shop_id}/payment/account',
    },
    SERVICE: {
        LIST: '/shop/{shop_id}/service',
        CREATE: '/shop/{shop_id}/service',
        UPDATE: '/shop/{shop_id}/service/{id}',
        DELETE: '/shop/{shop_id}/service/{id}',
        GET: '/shop/{shop_id}/service/{id}',
    },
    ADMINS: {
        LIST: '/shop/{shop_id}/admins',
        CREATE: '/shop/{shop_id}/admins',
        UPDATE: '/shop/{shop_id}/admins/{id}',
        DELETE: '/shop/{shop_id}/admins/{id}',
    },
    STAFF: {
        LIST: '/shop/{shop_id}/staff',
        CREATE: '/shop/{shop_id}/staff',
        UPDATE: '/shop/{shop_id}/staff/{id}',
        DELETE: '/shop/{shop_id}/staff/{id}',
        GET: '/shop/{shop_id}/staff/{id}',
    },
    STAFF_ROLE: {
        LIST: '/shop/{shop_id}/staff/role',
        CREATE: '/shop/{shop_id}/staff/role',
        UPDATE: '/shop/{shop_id}/staff/role/{id}',
        DELETE: '/shop/{shop_id}/staff/role/{id}',
    },
    CONTRACT: {
        CREATE: '/shop/contract',
    },
};
