import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import MainRoutes from './routes/MainRoutes';
import ThemeProvider from './hooks/useTheme';
import { store } from './redux/store';
import 'react-toastify/dist/ReactToastify.css';
import ToastBodyContainer from './components/ToastContainer';

function App(): JSX.Element {
    return (
        <div className="h-full w-full bg-background relative">
            <Provider store={store}>
                <ThemeProvider>
                    <ToastBodyContainer />
                    <BrowserRouter>
                        <MainRoutes />
                    </BrowserRouter>
                </ThemeProvider>
            </Provider>
        </div>
    );
}
export default App;
