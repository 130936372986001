import React from 'react';
import { clearUser } from 'src/app/Auth/Login/Steps/Otp/otp.slice';
import Logo from 'src/assets/svgs/Logo.svg';
import { useAppDispatch } from 'src/redux/hooks';
import { logout } from 'src/utils/global-functions';

const Header = () => {
    const dispatch = useAppDispatch();

    const completeFun = () => {
        dispatch(clearUser());
        logout();
    };

    return (
        <header className="py-5 px-10 border-b">
            <div className="">
                <img onClick={completeFun} src={Logo} className="h-[24px] cursor-pointer" alt="Flair" />
            </div>
        </header>
    );
};
export default Header;
