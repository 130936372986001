import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { API } from 'src/constants/api';
import { RootState } from 'src/redux/store';
import { axiosPost } from 'src/utils/axios';

const initialState = {
    loading: false,
    hasErrors: false,
    hasErrorsList: {},
};

export const CreateStripeToken = createAsyncThunk('/shop/token', async (payload: any, { rejectWithValue }) => {
    try {
        const response: any = await axiosPost(API.STRIPE.CREATE, payload);
        if (response.data.status === 200) {
            return response.data;
        }

        return rejectWithValue({
            data: response,
        } as any);
    } catch (err: any) {
        if (!err.response) {
            throw err;
        }

        return rejectWithValue({
            message: err.response.data,
        } as any);
    }
});

export const PaymentMethod = createSlice({
    name: 'account-detail',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(CreateStripeToken.pending, (state) => {
                state.loading = true;
                state.hasErrors = false;
            })
            .addCase(CreateStripeToken.fulfilled, (state, { payload }) => {
                state.loading = false;
                state.hasErrors = false;
            })
            .addCase(CreateStripeToken.rejected, (state, { payload }: { payload: any }) => {
                state.loading = false;
                state.hasErrors = true;
                state.hasErrorsList = payload
                    ? {
                          error: payload,
                          plan_id: payload?.data?.data?.data?.plan_id?.[0],
                      }
                    : { error: 'Unknown error' };
            });
    },
});

export const selectLoading = (state: RootState) => state.PaymentMethod.loading;
